import { http, useVzFetch } from '~/composables/useVzFetch';
import { IContactsResponse, IFeedbackForm } from '~~/types/api';
import {useMemoize} from '@vueuse/core';
import {FeedbackMessage} from '~/types/forms';

export const getContactsInfo = useMemoize(
    (location: string, destination?: any) => {
      return useVzFetch(
          `${http}/contacts/get`,
          {method: 'POST', body: {location, destination}}
      ) as Promise<IContactsResponse>
    }
)

export function sendFeedback(body: IFeedbackForm): Promise<{ message: string, error: any }> {
  return useVzFetch(
      `${http}/contacts/feedback`,
      { method: 'post', body, credentials: 'include' }
  ) as Promise<{ message: string, error: any }>
}

export const getFeedbackForms = useMemoize(() => {
      return useVzFetch<FeedbackMessage[]>(`${httpApiV3}/feedback-message`)
    }
)

export function createFeedback(form: FormData): Promise<any> {
  return useVzFetch(
    `${httpApiV3}/feedback-message`,
    { method: 'post', body: form, credentials: 'include' }
  ) as Promise<any>
}

export function createTicket(content: string) {
  return useVzFetch(
    `${httpApiV3}/personal/ticket`,
    { method: 'post', body: { content }, manual: true }
  )
}
